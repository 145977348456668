// Here you can add other styles
.multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 33.5px !important; /* Height */
}

.dividir-espacio-bottom {
  margin-bottom: 2%;
  margin-left: -15px;
}
// Altura del Mapa para tener mismo tamaño que el DataTable (aún no determinado)
.mapa-container {
  max-height: 75vh;
  overflow: auto;
}

.letra-negrita {
  font-weight: bold;
}

div.position-relative.table-responsive > table.table.table-hover {
  max-height: 500px !important;
  overflow: scroll !important;
}
.table-scroll-auto {
  position: relative;
}
.table-recorrido-estado > .table-responsive {
  max-height: 600px !important;
  overflow: auto !important;
}
.check-select-todos {
  position: absolute;
  right: 100px;
  top: 5px;
  z-index: 2;
  input {
    cursor: pointer;
  }
}
.check-colores {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 2;
  input {
    cursor: pointer;
  }
}

.chart_container {
  div {
    .chartjs-render-monitor {
      min-height: calc(100vh - 450px) !important;
      max-height: 100vh;
    }
  }
}
.table-detallado {
  //max-height: 400px;
  //overflow: auto;
}
.table-resumen {
  //max-height: 300px;
  //overflow: auto;
}
